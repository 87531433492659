import { range } from 'lodash';
import { AccessibilityProps } from 'react-native';
import { IconDot } from '../icons/IconDot';
import { Layout } from './index';

export { Progress } from 'native-base';

type ProgressDotsProps = AccessibilityProps & {
  accessibilityContext: string;
  maxValue: number;
  value: number;
};

export const ProgressDots = ({
  accessibilityContext,
  maxValue,
  value,
  ...accessibilityProps
}: ProgressDotsProps): JSX.Element => {
  const dotCount = Math.max(maxValue, value, 1);

  const activeColor = 'primary.600';
  const inactiveColor = 'primary.alpha.20:alpha.20';

  const label = `${accessibilityContext} progress ${value} of ${dotCount}.`;

  return (
    <Layout.HStack space={4} accessibilityLabel={label} {...accessibilityProps}>
      {range(1, dotCount + 1).map(currentDot => (
        <IconDot
          accessibilityHidden
          key={currentDot}
          size={5}
          color={currentDot === value ? activeColor : inactiveColor}
        />
      ))}
    </Layout.HStack>
  );
};
