import React, { useCallback, useEffect } from 'react';
import { useVimeo, VimeoOptions } from './useVimeo';
import type { VimeoProps } from './types';

type Props = VimeoOptions & VimeoProps;

export const Vimeo = ({ videoId, height, width, ...vimeoOptions }: Props): JSX.Element => {
  const containerRef = useCallback((refEl: HTMLDivElement | null) => {
    if (refEl !== null) {
      void initializePlayer(refEl);
    }
  }, []);

  const { initializePlayer, pauseVideo, setTextTrack, setVolume } = useVimeo({
    videoId,
    vimeoOptions,
  });

  useEffect(() => {
    if (vimeoOptions.isPaused === true) {
      void pauseVideo();
    }
  }, [vimeoOptions?.isPaused]);

  useEffect(() => {
    void setTextTrack(vimeoOptions?.textTrack);
  }, [vimeoOptions?.textTrack]);

  useEffect(() => {
    if (vimeoOptions?.volume !== undefined) {
      void setVolume(vimeoOptions.volume);
    }
  }, [vimeoOptions?.volume]);

  const style = {
    width: width ?? '100%',
    height: height ?? '100%',
    overflow: 'hidden',
  };

  return <div ref={containerRef} style={style} />;
};
