import { has, map } from 'lodash';
import { Select as NativeBaseSelect, ISelectProps } from 'native-base';
import React, { ForwardedRef, forwardRef, RefObject } from 'react';

export type SelectProps<OptionValue extends string> = Omit<ISelectProps, 'onValueChange'> & {
  onValueChange?: (itemValue: OptionValue) => void;
  options: Record<OptionValue, string>;
};

export const Select = forwardRef(
  <OptionValue extends string>(
    { onValueChange, options, ...selectProps }: SelectProps<OptionValue>,
    ref: ForwardedRef<typeof NativeBaseSelect>,
  ): JSX.Element => {
    const onChanged = (itemValue: string): void => {
      if (onValueChange === undefined) {
        return;
      }

      if (has(options, itemValue)) {
        onValueChange(itemValue as OptionValue);
      }
    };

    // I'm still not 100% sure why we have to change this type of RefObject...
    const innerRef = ref as RefObject<typeof NativeBaseSelect>;

    return (
      <NativeBaseSelect {...selectProps} onValueChange={onChanged} ref={innerRef}>
        {map(options, (optionLabel, optionValue) => (
          <NativeBaseSelect.Item key={optionValue} label={optionLabel} value={optionValue} />
        ))}
      </NativeBaseSelect>
    );
  },
);
