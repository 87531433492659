import { PresenceTransition } from 'native-base';
import { ReactNode } from 'react';

type SlideTransitionProps = {
  back?: boolean;
  children: ReactNode;
  maxHeight?: number;
  screenWidth?: number;
  visible?: boolean;
};

export const SlideTransition = ({
  children,
  maxHeight,
  back = false,
  screenWidth = 500,
  visible = false,
}: SlideTransitionProps): JSX.Element => {
  const enterInitial = { opacity: 0, translateX: !back ? -screenWidth : screenWidth } as const;
  const exitInitial = { opacity: 0, translateX: !back ? screenWidth : -screenWidth } as const;

  const animation = { opacity: 1, translateX: 0, transition: { duration: 300 } } as const;

  const style = {
    maxHeight,
    height: '100%',
    position: visible ? undefined : 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: visible ? 1 : 0,
  } as const;

  return (
    <PresenceTransition
      visible={visible}
      initial={visible ? exitInitial : enterInitial}
      animate={animation}
      style={style}
    >
      {children}
    </PresenceTransition>
  );
};
