import { Button, Heading, Layout } from '../../../components/core';
import { HeadingProps } from '../../../components/core/Heading';
import { getStylesheet } from '../../../styles';

type InterstitialSectionProps = {
  imageComponent: JSX.Element;
  header: string;
  headerStyles?: HeadingProps;
  description?: JSX.Element;
  buttonLabel?: string;
  buttonAction?: () => void;
};

export const InterstitialSection = ({
  imageComponent,
  header,
  headerStyles,
  description,
  buttonLabel,
  buttonAction,
}: InterstitialSectionProps): JSX.Element => {
  return (
    <Layout.VStack {...styles.stack} space={7}>
      {imageComponent}
      <Heading.h2 {...headerStyles}>{header}</Heading.h2>
      {description}
      <Button.primaryLarge
        alignSelf="stretch"
        testID="button-interstitial-start-assessment"
        onPress={buttonAction}
      >
        {buttonLabel}
      </Button.primaryLarge>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  stack: {
    flex: 1,
    alignItems: 'center',
  },
});
