import { defaultTo } from 'lodash';
import { useContrastText } from 'native-base';
import React from 'react';
import { SectionDocument } from '../../../../.slicemachine/prismicio';
import { Heading, Image, Layout, Pressable, Text } from '../../../components/core';
import { IconPlay } from '../../../components/icons/IconPlay';
import { getStylesheet } from '../../../styles';
import { getColorFromShade } from '../../../utils/color';
import { ModuleDocument } from '../../module/ModuleTypes';

type SectionBoxProps = {
  module: ModuleDocument;
  onPress: () => void;
  section: SectionDocument;
  showContinue?: boolean;
};

export const SectionBox = ({ module, onPress, section }: SectionBoxProps): JSX.Element => {
  const moduleData = module.data;
  const imageUrl = defaultTo(moduleData.image?.url, '');
  const imageAlt = defaultTo(moduleData.image?.alt, 'Image');

  const sectionData = section.data;
  const title = defaultTo(sectionData.title, '');

  const backgroundColor = getColorFromShade(
    moduleData.color ?? '',
    moduleData.color_shade,
    'secondary.900',
  );

  // Determines the text color based on the background color.
  const textColor = useContrastText(backgroundColor);

  return (
    <Pressable
      {...styles.sectionBox}
      backgroundColor={backgroundColor}
      testID="topic-module-box"
      onPress={onPress}
    >
      <Layout.HStack {...styles.sectionBoxStack} space={4}>
        {imageUrl && (
          <Image
            {...styles.moduleThumbnail}
            alt={imageAlt}
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}

        <Layout.VStack flex={1} space={1}>
          <Text.caption bold color={textColor}>
            Continue
          </Text.caption>

          <Heading.h4 color={textColor}>{title}</Heading.h4>
        </Layout.VStack>

        <IconPlay color={textColor} size={6} />
      </Layout.HStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  sectionBox: {
    backgroundColor: 'secondary.50',
    borderRadius: '8px',
    flex: 1,
    padding: 4,
    shadow: 'medium',
  },

  sectionBoxStack: {
    alignItems: 'center',
    textAlign: 'left',
  },

  moduleThumbnail: {
    alignSelf: 'center',
    size: '42px',
  },
});
