import { Text, Layout } from '../../components/core';
import { PageLoading } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { ImageHands } from './components/ImageHands';
import { InterstitialSection } from './components/InterstitialSection';
import { useFindCareContext } from './context/findCareContext';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export const FindCareStartCarePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLoading, recommendationIsActive, setHasStarted } = useFindCareContext();

  useFindCareRedirect({ isStart: true });

  const onStartPress = (): void => {
    setHasStarted(true);

    navigate(getRoute('findCareConfirmInfo', {}));
  };

  if (isLoading || recommendationIsActive) {
    // The useFindCareRedirect will redirect if recommendation is active, so keep showing loading
    // screen, so it doesn't blip.
    return <PageLoading pageName="find care" />;
  }

  return (
    <FindCarePageWrapper testID="find-care-start-page" showProgress={false}>
      <InterstitialSection
        imageComponent={<ImageHands size={194} />}
        header="We’re here to help with the next steps of your mental health journey"
        description={
          <Layout.VStack space={4}>
            <Text.para>
              Taking care of your mental health is essential. This assessment has been carefully
              designed to connect you to the most suitable level of care for your unique needs.
            </Text.para>
            <Text.para>Completing this form will take no more than 10 minutes.</Text.para>
          </Layout.VStack>
        }
        buttonLabel="Start assessment"
        buttonAction={onStartPress}
      />
    </FindCarePageWrapper>
  );
};
