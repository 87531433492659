import { StyledProps } from 'native-base';
import { JSX } from 'react';
import { Heading, Text, Pressable, Layout } from '..';
import { getStylesheet } from '../../../styles';
import { IconCalendar } from '../../icons/IconCalendar';
import { IconChevronRight } from '../../icons/IconChevronRight';
import { IconListChecks } from '../../icons/IconListChecks';
import { IconMessageCircle } from '../../icons/IconMessageCircle';
import { IconUser } from '../../icons/IconUser';

export type TaskDetails = {
  title: string;
  onPress: () => void;
  completed: boolean;
  description?: string;
  timeToComplete?: string;
  type?: 'account' | 'assessment' | 'event' | 'message';
};

export type TaskProps = StyledProps & TaskDetails;

const IconMap = {
  account: IconUser,
  assessment: IconListChecks,
  event: IconCalendar,
  message: IconMessageCircle,
};

export const Task = ({
  description,
  onPress,
  timeToComplete,
  title,
  type = 'assessment',
  completed,
}: TaskProps): JSX.Element | null => {
  if (completed) {
    return null;
  }

  const Icon = IconMap[type];

  return (
    <Pressable accessibilityRole="button" testID="pressable-task" onPress={onPress}>
      <Layout.HStack {...styles.task} space={3}>
        <Layout.Flex {...styles.icon}>
          <Icon size={6} />
        </Layout.Flex>

        <Layout.VStack {...styles.title}>
          <Layout.HStack {...styles.titleAndTime} space={1}>
            <Heading.h6>{title}</Heading.h6>

            <Text.caption>{timeToComplete}</Text.caption>
          </Layout.HStack>

          {description !== undefined && (
            <Text.paraSmall numberOfLines={1}>{description}</Text.paraSmall>
          )}
        </Layout.VStack>

        <Layout.HStack {...styles.chevronContainer}>
          <IconChevronRight size={6} />
        </Layout.HStack>
      </Layout.HStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  chevronContainer: {
    alignItems: 'center',
  },

  icon: {
    backgroundColor: 'primary.alpha.20:alpha.20',
    borderRadius: 8,
    justifyContent: 'center',
    padding: 2,
  },

  task: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'space-between',
    padding: 3,
  },

  title: {
    flex: 1,
    justifyContent: 'center',
  },

  titleAndTime: {
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
});
