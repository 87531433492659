import React from 'react';
import { Platform } from 'react-native';
import { Heading, Layout, Text } from '../../../components/core';
import { Vimeo } from '../../../components/vimeo/Vimeo';
import { getStylesheet } from '../../../styles';

export const AboutTheseGuides = (): JSX.Element => {
  return (
    <Layout.VStack {...styles.wrapper} space={4} testID="about-these-guides">
      <Heading.h3 center>About These Guides</Heading.h3>

      <Vimeo videoId="780492233/bb11852abb" height={Platform.OS !== 'web' ? 200 : undefined} />

      <Text.para {...styles.text}>
        This program is here to help you navigate every part of your life. Take a look at a few of
        our bite-sized skills and get closer to living your best life.
      </Text.para>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  wrapper: {
    backgroundColor: 'secondary.200',
    borderRadius: 16,
    paddingX: 4,
    paddingY: 12,
  },

  text: {
    textAlign: 'center',
  },
});
