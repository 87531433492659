import { PresenceTransition } from 'native-base';
import { ReactNode } from 'react';
import { Dimensions } from 'react-native';

type SkillTransitionProps = {
  back?: boolean;
  children: ReactNode;
  visible?: boolean;
};

export const SkillTransition = ({
  back = false,
  children,
  visible = false,
}: SkillTransitionProps): JSX.Element => {
  const enterInitial = { opacity: 0, translateX: !back ? -500 : 500 } as const;
  const exitInitial = { opacity: 0, translateX: !back ? 500 : -500 } as const;

  const animation = { opacity: 1, translateX: 0, transition: { duration: 300 } } as const;

  const windowHeight = Dimensions.get('window').height;
  const style = {
    maxHeight: Math.round(windowHeight) - 73, // 73px is the height of the skill header.
    height: '100%',
    position: visible ? undefined : 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: visible ? 1 : 0,
  } as const;

  return (
    <PresenceTransition
      visible={visible}
      initial={visible ? exitInitial : enterInitial}
      animate={animation}
      style={style}
    >
      {children}
    </PresenceTransition>
  );
};
