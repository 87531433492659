import { Heading, Layout, Text } from '../../../components/core';
import { ImageHandAndCheckboxes } from '../images/ImageHandAndCheckboxes';
import { CoachingAssessmentStartModal, StartModalProps } from './CoachingAssessmentStartModal';

export const CoachingGoalsStartModal = ({ ...startProps }: StartModalProps): JSX.Element => {
  return (
    <CoachingAssessmentStartModal title="Setting Goals" {...startProps}>
      <Layout.VStack space={4}>
        <Layout.Box alignItems="center" maxWidth="100%">
          <ImageHandAndCheckboxes />
        </Layout.Box>

        <Heading.h6>Step 1</Heading.h6>

        <Heading.h3>Set your goals</Heading.h3>

        <Text.para>
          Let’s start by learning a little bit more about your needs for coaching.
        </Text.para>

        <Text.para>
          These questions will give your coach a snapshot into some of the issues you’re
          experiencing, and allow your coach to tailor the program to best support you.
        </Text.para>
      </Layout.VStack>
    </CoachingAssessmentStartModal>
  );
};
