import React from 'react';
import { ImageProps } from 'react-native';
import { getStylesheet } from '../../../styles';
import { Layout, Text } from '../../core';
import { IconUser } from '../../icons/IconUser';

type UserInitialsProps = Omit<ImageProps, 'source'> & {
  initials: string;
  size?: number;
};

export const UserInitials = ({ initials, size = 8 }: UserInitialsProps): JSX.Element => {
  if (!initials) {
    return (
      <Layout.View {...styles.wrapper}>
        <IconUser {...styles.icon} size={size} />
      </Layout.View>
    );
  }

  return (
    <Layout.View {...styles.wrapper}>
      <Text.paraLarge {...styles.localStyle} accessible={false} width={size} height={size} center>
        {initials}
      </Text.paraLarge>
    </Layout.View>
  );
};

const styles = getStylesheet({
  wrapper: {
    backgroundColor: 'secondary.100',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'secondary.300',
    overflow: 'hidden',
  },

  icon: {
    color: 'secondary.300',
  },

  localStyle: {
    color: 'secondary.700',
    display: 'flex',
    fontSize: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});
