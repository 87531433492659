import { Button, Heading, Layout, SimpleModal, Text } from '../../../components/core';
import {
  HubUserHealthieAppointmentData,
  useMarkHealthieAppointmentForCancellationMutation,
} from '../../../graphQL';
import { useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type RescheduleRedirectNoticeModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  rescheduleAppointmentLink: string;
  upcomingAppointment: HubUserHealthieAppointmentData;
};

export const RescheduleRedirectNoticeModal = ({
  onClose,
  onConfirm,
  rescheduleAppointmentLink,
  upcomingAppointment,
}: RescheduleRedirectNoticeModalProps): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  const [markAppointmentCancelled] = useMarkHealthieAppointmentForCancellationMutation();

  const onContinuePressed = (): void => {
    externalNavigate(rescheduleAppointmentLink);
    void markAppointmentCancelled({
      variables: {
        appointmentId: upcomingAppointment.id,
        appointmentTypeId: upcomingAppointment.appointmentTypeId,
      },
    });
    onConfirm();
  };

  return (
    <SimpleModal size="md" onClose={onClose}>
      <Layout.VStack {...styles.topStack} space={6}>
        <Layout.VStack space={2}>
          <Heading.h4>Are you sure you want to reschedule?</Heading.h4>

          <Text.para>
            This will cancel your upcoming appointment and redirect you to a third-party platform to
            schedule a new appointment.
          </Text.para>
        </Layout.VStack>

        <Layout.HStack justifyContent="flex-start" space={3}>
          <Button.secondaryMedium
            testID="button-reschedule-redirect-notice-modal-close"
            onPress={onClose}
          >
            Go Back
          </Button.secondaryMedium>

          <Button.dangerMedium
            testID="button-reschedule-redirect-notice-modal-confirm"
            onPress={onContinuePressed}
          >
            Continue
          </Button.dangerMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  topStack: {
    paddingTop: 8,
  },
});
