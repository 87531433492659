import { isNil } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormChips, FormSelect } from '../../../components/form';
import { useFormScrolling } from '../../../components/form/hooks/useFormScrolling';
import { PageLoading } from '../../../components/page';
import { useSaveUserDemographicsMutation, useUserDemographicsQuery } from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';
import { FindCarePageHeader } from '../components/FindCarePageHeader';

type FormValues = {
  genderIdentity: string[];
  ethnicity: string[];
  isInternationalStudent: 'true' | 'false' | '';
  sexualOrientation: string[];
  academicProgram: string[];
  yearInProgram: string;
};

const genderOptions = {
  agender: 'Agender',
  cisgenderMan: 'Cisgender Man',
  cisgenderWoman: 'Cisgender Woman',
  genderfluid: 'Genderfluid',
  nonBinary: 'Nonbinary',
  transgenderMan: 'Transgender Man',
  transgenderWoman: 'Transgender Woman',
  unsure: 'Unsure',
  preferNotToSay: 'Prefer not to say',
  notListed: 'Another option not listed here',
};

const raceOptions = {
  nativeAmerican: 'American Indian or Alaska Native',
  asian: 'Asian',
  africanAmerican: 'Black or African American',
  hispanic: 'Hispanic, Latino or Spanish origin',
  mideastern: 'Middle Eastern or North African',
  nativeHawaiian: 'Native Hawaiian or Other Pacific Islander',
  white: 'White',
  notListed: 'Some other race, ethnicity or origin',
};

const sexualOrientationOptions = {
  asexual: 'Asexual',
  bisexual: 'Bisexual',
  pansexual: 'Pansexual',
  straight: 'Straight',
  lesbian: 'Lesbian',
  gay: 'Gay',
  queer: 'Queer',
  unsure: 'Unsure',
  preferNotToSay: 'Prefer not to say',
  notListed: 'Another option not listed here',
};

const academicOptions = {
  tech: 'Associates or other 2-year technical degree',
  bachelor: 'Bachelor’s Program or other 4-year degree',
  postBachelor: 'Graduate, Professional, or other post-bachelor program',
  online: 'Online-only student',
  nonDegree: 'Continuing education and/or non-degree seeking student',
  notListed: 'Other',
  unsure: 'Unsure',
};

const academicYearsOptions = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
  eightOrMore: '8+',
  unsure: 'Unsure',
};

export const DemographicsForm = (): JSX.Element => {
  const { loading } = useUserDemographicsQuery({
    onCompleted: ({ userDemographics }) => {
      if (isNil(userDemographics)) {
        return;
      }

      setValue('genderIdentity', userDemographics.genderIdentity.split(','));
      setValue('ethnicity', userDemographics.ethnicity.split(','));
      setValue(
        'isInternationalStudent',
        userDemographics.isInternationalStudent ? 'true' : 'false',
      );
      setValue('sexualOrientation', userDemographics.sexualOrientation.split(','));
      setValue('academicProgram', userDemographics.academicProgram.split(','));
      setValue('yearInProgram', userDemographics.yearInProgram);
    },
  });

  const formContext = useForm<FormValues>({
    defaultValues: {
      genderIdentity: [] as string[],
      ethnicity: [] as string[],
      sexualOrientation: [] as string[],
      isInternationalStudent: '',
      academicProgram: [] as string[],
      yearInProgram: '',
    },
  });

  const {
    clearErrors,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const { onLayout, scrollToFirstError } = useFormScrolling<FormValues>(errors);

  const navigate = useNavigate();
  const [save] = useSaveUserDemographicsMutation({
    onCompleted: () => navigate(getRoute('findCarePreTriage', {})),
  });

  const submitForm = (): void => {
    // Scroll to the first error if there are any.
    scrollToFirstError();

    clearErrors();

    void handleSubmit(async (result): Promise<void> => {
      void save({
        variables: {
          input: {
            ...result,
            genderIdentity: result.genderIdentity.join(','),
            sexualOrientation: result.sexualOrientation.join(','),
            ethnicity: result.ethnicity.join(','),
            isInternationalStudent: result.isInternationalStudent === 'true',
            academicProgram: result.academicProgram.join(','),
          },
        },
      });
    })();
  };

  if (loading) {
    return <PageLoading pageName="account settings" />;
  }

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={10}>
        <FindCarePageHeader title="Tell us more about you" paddingBottom={0} />

        <FormChips
          name="genderIdentity"
          onLayout={onLayout('genderIdentity')}
          options={genderOptions}
          control={control}
          error={errors.genderIdentity}
          necessityIndicator
          label="What is your gender identity?"
          isMulti
        />

        <FormChips
          name="ethnicity"
          onLayout={onLayout('ethnicity')}
          label="Which category (or categories) best describe(s) your race/ethnicity?"
          options={raceOptions}
          control={control}
          error={errors.ethnicity}
          isRequired
          isMulti
          necessityIndicator
        />

        <FormSelect
          name="isInternationalStudent"
          onLayout={onLayout('isInternationalStudent')}
          options={{ true: 'Yes', false: 'No' }}
          placeholder="Select One"
          control={control}
          error={errors.isInternationalStudent}
          isRequired
          label="Are you an international student?"
          accessibilityLabel="Select International Student Status"
          necessityIndicator
        />

        <FormChips
          name="sexualOrientation"
          onLayout={onLayout('sexualOrientation')}
          label="What category best describes your sexual orientation?"
          options={sexualOrientationOptions}
          control={control}
          error={errors.sexualOrientation}
          isMulti
          necessityIndicator
        />

        <FormChips
          name="academicProgram"
          onLayout={onLayout('academicProgram')}
          label="What type of academic program are you currently in?"
          options={academicOptions}
          control={control}
          error={errors.academicProgram}
          isMulti
          isRequired
          necessityIndicator
        />

        <FormSelect
          name="yearInProgram"
          onLayout={onLayout('yearInProgram')}
          options={academicYearsOptions}
          placeholder="Select One"
          control={control}
          error={errors.yearInProgram}
          isRequired
          label="How many years have you been in your current academic program?"
          accessibilityLabel="Select Academic Years"
          necessityIndicator
        />

        <Button.primaryLarge testID="button-user-demographic-form-submit" onPress={submitForm}>
          Submit
        </Button.primaryLarge>
      </Layout.VStack>
    </FormProvider>
  );
};
