import { defaultTo } from 'lodash';
import { Button, Heading, Layout, Text } from '../../../../components/core';
import { getRoute, useNavigate } from '../../../../routes';
import { ModuleDocument } from '../../../module/ModuleTypes';
import { ImageBlush } from './ImageBlush';

type EndOfModuleProps = {
  module: ModuleDocument;
};

export const EndOfModule = ({ module }: EndOfModuleProps): JSX.Element => {
  const navigate = useNavigate();

  const onGoBackPress = (): void => {
    navigate(getRoute('module', { moduleId: module.id }));
  };

  const title = defaultTo(module.data.title, 'this module');

  return (
    <Layout.VStack space={8}>
      <Layout.Center>
        <ImageBlush size={251} />
      </Layout.Center>

      <Layout.VStack space={4}>
        <Heading.h2 center>You’ve reached the end of this module.</Heading.h2>

        <Text.para center>There are still more skills to complete in {title}.</Text.para>
      </Layout.VStack>

      <Button.skillLarge
        alignSelf="center"
        testID="button-skill-end-of-module-go-back"
        onPress={onGoBackPress}
      >
        Go Back
      </Button.skillLarge>
    </Layout.VStack>
  );
};
