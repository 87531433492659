import React, { useMemo } from 'react';
import { Heading, Progress } from '../../../components/core';
import { PageContent, PageTopBar } from '../../../components/page';
import { getRoute, routes, useLocation, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

const onDemandRoutes = [
  routes.onDemandConfirmInfo[0],
  routes.onDemandPreEncounterForm[0],
  routes.onDemandInQueue[0],
] as const;

export const OnDemandPageWrapper = ({
  children,
  testID,
}: {
  children: JSX.Element;
  testID: string;
}): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const goBack = (): void => {
    const currentPageIdx = onDemandRoutes.findIndex(route => route === pathname);
    const previousPage = onDemandRoutes[currentPageIdx - 1];

    if (previousPage !== undefined) {
      navigate(previousPage, {});
    }
  };

  const goHome = (): void => {
    navigate(getRoute('onDemandHome', {}));
  };

  const getProgress = useMemo(() => {
    const currentPageIdx = onDemandRoutes.findIndex(route => route === pathname);
    return ((currentPageIdx + 1) / onDemandRoutes.length) * 100;
  }, [pathname]);

  return (
    <PageContent testID={testID} padding={1}>
      <PageTopBar
        backAccessibilityLabel="Navigate to on demand home page."
        onBackPress={goBack}
        onClosePress={goHome}
      >
        <Heading.h5 center paddingY={3}>
          On-Demand Emotional Support
        </Heading.h5>
      </PageTopBar>

      <Progress
        {...styles.progress}
        accessibilityLabel="On demand progress."
        variant="skill"
        value={getProgress}
      />

      {children}
    </PageContent>
  );
};

const styles = getStylesheet({
  progress: {
    width: '70%',
    alignSelf: 'center',
    marginBottom: 4,
  },
});
