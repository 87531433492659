import React from 'react';
import { getRoute, useNavigate } from '../../routes';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { ImageCouch } from './components/ImageCouch';
import { InterstitialSection } from './components/InterstitialSection';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export const FindCarePreQuantitativePage = (): JSX.Element => {
  const navigate = useNavigate();

  useFindCareRedirect();

  return (
    <FindCarePageWrapper testID="find-care-pre-quantitative-page">
      <InterstitialSection
        imageComponent={<ImageCouch size={194} />}
        header={`We appreciate your honesty. \n\n Finally, let's talk more about your recent symptoms.`}
        headerStyles={{ textAlign: 'center' }}
        buttonLabel="Continue"
        buttonAction={() => {
          navigate(
            getRoute('findCareAssessments', {
              assessmentType: 'phq',
              slide: '1',
            }),
          );
        }}
      />
    </FindCarePageWrapper>
  );
};
