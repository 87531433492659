import { castArray, chain } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { FieldErrors, FieldValues, UseControllerProps, useWatch } from 'react-hook-form';
import { Heading, Layout, StatusFeedback, Text } from '../../../components/core';
import { FormChips, FormInput, FormTextArea } from '../../../components/form';
import { FormLabel } from '../../../components/form/FormLabel';
import { getQuestionOptionsText, parseOptionFromKey, QuestionPlus } from '../utils/assessmentUtils';

type FormAssessmentQuestionProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & {
    error?: FieldErrors<TFieldValues>[string];
    inputName: UseControllerProps<TFieldValues>['name'];
    inputError?: FieldErrors<TFieldValues>[string];
    question: QuestionPlus;
  };

const QuestionWrapper = ({
  children,
  question,
}: {
  children: ReactNode;
  question: QuestionPlus;
}): JSX.Element => {
  return (
    <Layout.VStack space={6}>
      {Boolean(question.description) && <Text.para>{question.description}</Text.para>}

      {question.preText != null && <Text.para>{question.preText}</Text.para>}

      <Heading.h3>{question.text ?? ''}</Heading.h3>

      {question.subText != null && <Text.para>{question.subText}</Text.para>}

      {children}
    </Layout.VStack>
  );
};

export const FormAssessmentQuestion = <TFieldValues extends FieldValues>({
  question,
  inputName,
  ...controllerProps
}: FormAssessmentQuestionProps<TFieldValues>): JSX.Element => {
  const options = useMemo(() => getQuestionOptionsText(question), [question]);

  const currentAnswer = useWatch({ control: controllerProps.control, name: controllerProps.name });

  if (options !== undefined) {
    const selectedOptions = parseOptionFromKey(question, currentAnswer);
    const manualInput = question.manualInput ?? undefined;
    const needsManualInput = chain(castArray(selectedOptions))
      .compact()
      .some(({ requiresManualInput }) => Boolean(requiresManualInput))
      .value();

    return (
      <QuestionWrapper question={question}>
        <FormChips
          {...controllerProps}
          isMulti={question.type?.includes('multi') ?? false}
          isRequired={question.isOptional !== true}
          options={options}
        />

        {needsManualInput && (
          <Layout.VStack space={2}>
            <FormLabel
              isRequired
              necessityIndicator
              label={manualInput?.label ?? 'Please specify'}
            />

            <FormTextArea
              name={inputName}
              control={controllerProps.control}
              error={controllerProps.inputError}
              isRequired
              label={question.label ?? ''}
              numberOfLines={3}
              placeholder={manualInput?.placeholder ?? ''}
              placeholderTextColor="secondary.700"
            />
          </Layout.VStack>
        )}
      </QuestionWrapper>
    );
  }

  if (question.type === 'free-paragraph-field') {
    return (
      <QuestionWrapper question={question}>
        <FormTextArea
          {...controllerProps}
          isRequired={question.isOptional !== true}
          label={question.label ?? ''}
          necessityIndicator
          numberOfLines={6}
          placeholder={question?.placeholder ?? ''}
          placeholderTextColor="secondary.700"
        />
      </QuestionWrapper>
    );
  }

  if (question.type === 'free-text-field') {
    return (
      <QuestionWrapper question={question}>
        <FormInput
          {...controllerProps}
          isRequired={question.isOptional !== true}
          label={question.label ?? ''}
          placeholder={question?.placeholder ?? ''}
        />
      </QuestionWrapper>
    );
  }

  return (
    <StatusFeedback.warning testID="assessment-question">
      Could not parse question.
    </StatusFeedback.warning>
  );
};
