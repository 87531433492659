import { getStylesheet } from '../../styles';
import { Button, Heading, Layout, SimpleModal, Text } from '../core';

type ConfirmLeaveModalProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmLeaveModal = ({ onClose, onConfirm }: ConfirmLeaveModalProps): JSX.Element => {
  return (
    <SimpleModal size="md" onClose={onClose}>
      <Layout.VStack {...styles.topStack} space={6}>
        <Layout.VStack space={2}>
          <Heading.h4>Are you sure you want to leave?</Heading.h4>

          <Text.para>Your responses may not be saved</Text.para>
        </Layout.VStack>

        <Layout.HStack justifyContent="flex-start" space={3}>
          <Button.secondaryMedium testID="button-confirm-modal-close" onPress={onClose}>
            Go Back
          </Button.secondaryMedium>

          <Button.dangerMedium testID="button-confirm-modal-confirm" onPress={onConfirm}>
            Leave
          </Button.dangerMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  topStack: {
    paddingTop: 8,
  },
});
