import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Skeleton } from '../../../components/core';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { SectionBox } from '../../../content/section/components/SectionBox';
import { getRoute } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { usePathwayNextSkill } from '../hooks/usePathwayNextSkill';
import { ImagePlane } from './ImagePlane';

export const YourPathway = (): JSX.Element => {
  const navigate = useNavigate();
  const [{ hasPathway, nextSkill, module, section }, { loading, error }] = usePathwayNextSkill();

  if (loading) {
    return <Skeleton h={200} />;
  }
  if (error) {
    return <></>;
  }

  if (!hasPathway) {
    const onStartQuizPress = (): void => {
      navigate(
        getRoute('selfCareQuiz', {
          slide: '1',
        }),
      );
    };

    return (
      <Layout.Shift reverseMobile {...styles.startBox}>
        <Layout.VStack {...styles.startBoxLeft} space={6}>
          <Heading.h3>Get your personalized skills pathway.</Heading.h3>

          <Button.primaryMedium
            testID="button-start-self-care-quiz"
            onPress={onStartQuizPress}
            alignSelf="flex-start"
          >
            Set Your Goals
          </Button.primaryMedium>
        </Layout.VStack>

        <ImagePlane height={105} width={191} />
      </Layout.Shift>
    );
  }

  if (!nextSkill || !module || !section) {
    return <></>;
  }

  const onViewPress = (): void => {
    navigate(getRoute('skillPathway', {}));
  };

  const onSectionPress = (): void => {
    navigate(
      getRoute(
        'skill',
        {
          skillId: nextSkill.id,
          moduleId: module.id,
          slide: '1',
        },
        {
          path: '1',
        },
      ),
    );
  };

  return (
    <Layout.VStack space={4}>
      <Layout.HStack {...styles.headerBar} space={1}>
        <Heading.h5>Your Pathway</Heading.h5>

        <Button.tertiarySmall
          rightIcon={<IconChevronRight />}
          testID="button-go-to-your-pathway"
          onPress={onViewPress}
        >
          View
        </Button.tertiarySmall>
      </Layout.HStack>

      <SectionBox module={module} section={section} onPress={onSectionPress} />
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  startBox: {
    alignItems: 'flex-start',
    backgroundColor: 'primary.100',
    borderRadius: '8px',
    flexDirection: 'row',
    gap: 6,
    justifyContent: 'space-between',
    padding: 8,
  },

  startBoxLeft: {
    flexShrink: 5,
    maxWidth: 340,
  },

  headerBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
