import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Pressable } from '../../../components/core';
import { getRoute, useNavigate } from '../../../routes';
import { IconMantraLogo } from '../../icons/IconMantraLogo';
import { ImageMantraHealth } from '../../images/ImageMantraHealth';

type AuthHeaderProps = {
  children: ReactNode;
};

export const SimpleHeader = ({ children }: AuthHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const onLogoPress = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <Layout.View {...styles.page}>
      <Layout.VStack space="44px">
        <Pressable
          accessibilityLabel="Navigate Home."
          accessibilityRole="link"
          testID="pressable-header-logo"
          onPress={onLogoPress}
        >
          <Layout.HStack {...styles.mantraLogo} space={2}>
            <IconMantraLogo size={6} accessibilityHidden />

            <ImageMantraHealth height={18} />
          </Layout.HStack>
        </Pressable>

        <Layout.View>{children}</Layout.View>
      </Layout.VStack>
    </Layout.View>
  );
};

const styles = StyleSheet.create({
  page: {
    height: '100%',
    minWidth: 300,
    paddingTop: 4,
    position: 'relative',
  },

  mantraLogo: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
