import { JSX, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Text } from '../../components/core';
import { PageContent, PageFooter, PageTopBar } from '../../components/page';
import { getRoute } from '../../routes';
import { ResetPasswordForm } from './forms/ResetPasswordForm';

export const ResetPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();

  const [isPostSubmit, setIsPostSubmit] = useState(false);

  const onEmailSent = (): void => {
    setIsPostSubmit(true);
  };

  const onBackPress = (): void => {
    navigate(getRoute('login', {}));
  };

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="reset-password-page">
      {!isPostSubmit && (
        <>
          <PageTopBar
            backAccessibilityLabel="Back to Login."
            backTitle="Back"
            onBackPress={onBackPress}
            paddingBottom={8}
          />

          <Layout.VStack space={4}>
            <Heading.h3>Reset Password</Heading.h3>

            <Text.para>
              Enter your email address and we'll send you a link to reset your password.
            </Text.para>

            <ResetPasswordForm onEmailSent={onEmailSent} />
          </Layout.VStack>
        </>
      )}

      {isPostSubmit && (
        <Layout.VStack space={8}>
          <Layout.VStack space={4}>
            <Heading.h3>Reset Password</Heading.h3>

            <Text.para>
              Thank you! If that email address is found in our system, we’ll send you an email with
              instructions to reset your password.
            </Text.para>
          </Layout.VStack>

          <Button.secondaryMedium
            testID="button-reset-password-back-to-login"
            onPress={onBackPress}
          >
            Back to Login
          </Button.secondaryMedium>
        </Layout.VStack>
      )}

      <PageFooter />
    </PageContent>
  );
};
