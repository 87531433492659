import React from 'react';
import { PageHeader, PageTopBar } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';

export const ReflectionsHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackPressed = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  return (
    <>
      <PageTopBar
        backAccessibilityLabel="Navigate to the self care page."
        backTitle="Self Care"
        onBackPress={onBackPressed}
        testID="reflections-top-bar"
      />

      <PageHeader
        accessibilityLabel="Navigate to the self care page."
        title="Reflections"
        testID="reflections-header"
      />
    </>
  );
};
