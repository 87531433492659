import { isNil } from 'lodash';
import React, { useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import { Button, Heading, Layout, Skeleton, Text } from '../../../components/core';
import { IconChevronLeft } from '../../../components/icons/IconChevronLeft';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import {
  APP_CONTAINER_MAX_WIDTH_PX,
  APP_SIDEBAR_WIDTH_PX,
} from '../../../components/layout/app/appSizes';
import { ModuleDocument } from '../../../content/module/ModuleTypes';
import { SkillBox } from '../../../content/skill/SkillBox';
import { SkillDocument } from '../../../content/skill/SkillTypes';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { useGetJumpBackInSkillsQuery } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

type Skills = Array<{ skill: SkillDocument; module: ModuleDocument }>;

export const JumpBackIn = (): JSX.Element | null => {
  const { width } = useWindowDimensions();
  const { data, loading, error } = useGetJumpBackInSkillsQuery();

  const skills = (data?.getJumpBackInSkills ?? []).filter(({ module, skill }) => {
    return !isNil(module) && !isNil(skill);
  }) as Skills;

  const sliderRef = useRef<ICarouselInstance | null>(null);

  const isMobile: boolean = useBreakpointSwitch({
    mobile: true,
    desktop: false,
  });

  // Do some math to make sure the carousel is the correct size to fit the viewport size.
  const maxWidth = APP_CONTAINER_MAX_WIDTH_PX; // The max width of content on the page.
  const mainBodyWidth = isMobile ? width : width - APP_SIDEBAR_WIDTH_PX; // Remove the left bar width.
  const currentWidth = Math.min(mainBodyWidth, maxWidth);
  const carouselWidth = currentWidth - 64; // 64px of left and right padding.
  const carouselHeight = 235 + Math.round(20000 / carouselWidth);

  const skillsToShow = skills.length < 2 ? 1 : 2; // Show 2 items unless there is only 1, then show only 1.
  const carouselItemWidth = carouselWidth / skillsToShow + 4; // 4px of padding between carousel items.

  const showNavButtons = skills.length > 2;

  const renderItem = ({ module, skill }: (typeof skills)[number]): JSX.Element => {
    return (
      <Layout.VStack {...styles.module} key={skill.id} space={3}>
        <Text.para bold {...styles.moduleTitle}>
          {module.data.title}
        </Text.para>

        <SkillBox moduleId={module.id} skill={skill} />
      </Layout.VStack>
    );
  };

  const onPreviousPress = (): void => {
    sliderRef.current?.prev({
      count: 2,
    });
  };
  const onNextPress = (): void => {
    sliderRef.current?.next({
      count: 2,
    });
  };

  if (!loading && (error || !skills.length)) {
    return null;
  }

  if (loading) {
    return (
      <Layout.VStack marginY={4} space={4} testID="jump-back-in">
        <Skeleton height={351} />
      </Layout.VStack>
    );
  }

  return (
    <Layout.VStack space={4} testID="jump-back-in">
      <Layout.Flex {...styles.header}>
        <Heading.h4>Jump back in</Heading.h4>

        {showNavButtons && (
          <Layout.HStack space={2}>
            <Button.secondaryMedium
              accessibilityLabel="Show previous skills page."
              leftIcon={<IconChevronLeft size={4} accessibilityHidden />}
              testID="button-self-care-jump-back-in-prev-page"
              onPress={onPreviousPress}
            />
            <Button.secondaryMedium
              accessibilityLabel="Show next skills page."
              leftIcon={<IconChevronRight size={4} accessibilityHidden />}
              testID="button-self-care-jump-back-in-next-page"
              onPress={onNextPress}
            />
          </Layout.HStack>
        )}
      </Layout.Flex>

      <Layout.View {...styles.carouselWrapper}>
        <Carousel
          ref={sliderRef}
          width={carouselItemWidth}
          height={carouselHeight}
          enabled={false}
          data={skills}
          renderItem={({ item }) => renderItem(item)}
          scrollAnimationDuration={400}
          testID="jump-back-in-slides"
          style={{
            width: carouselWidth,
          }}
        />
      </Layout.View>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  carouselWrapper: {
    flex: 1,
    shadow: 'large',
  },

  module: {
    backgroundColor: 'secondary.100',
    borderRadius: 4,
    flex: 1,
    padding: 2,
    marginRight: 2,
    shadow: 'small',
  },

  moduleTitle: {
    marginTop: 1,
    textAlign: 'center',
  },
});
