import { createIcon, Path } from './index';

export const IconPlay = createIcon({
  title: 'A plus sign',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M5 3L19 12L5 21V3Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
