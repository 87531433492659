import { defaultTo } from 'lodash';
import React from 'react';
import { Heading, Image, Layout, Pressable, Text } from '../../../../../components/core';
import { IconChevronRight } from '../../../../../components/icons/IconChevronRight';
import { getRoute, useNavigate } from '../../../../../routes';
import { getStylesheet } from '../../../../../styles';
import { ModuleDocument } from '../../../../module/ModuleTypes';

type TopicModuleBoxProps = {
  module: ModuleDocument;
  showContinue?: boolean;
};

export const TopicModuleBox = ({ module, showContinue }: TopicModuleBoxProps): JSX.Element => {
  const navigate = useNavigate();
  const onTopicModulePress = (moduleId: string) => (): void => {
    navigate(getRoute('module', { moduleId }));
  };

  const data = module.data;
  const imageUrl = defaultTo(data.image?.url, '');
  const imageAlt = defaultTo(data.image?.alt, 'Image');
  const title = defaultTo(data.title, 'Module Title');
  const description = defaultTo(data.description, '');

  return (
    <Pressable
      {...styles.moduleBox}
      testID="topic-module-box"
      onPress={onTopicModulePress(module.id)}
    >
      <Layout.HStack {...styles.moduleBoxStack} space={2}>
        <Layout.VStack flex={1} space={2}>
          <Heading.h3>{title}</Heading.h3>

          <Text.paraSmall>{description}</Text.paraSmall>

          <Layout.HStack size={2} alignItems="center" marginTop={4}>
            <Text.paraSmall bold>{showContinue === true ? 'Continue' : 'Browse'}</Text.paraSmall>
            <IconChevronRight size={4} />
          </Layout.HStack>
        </Layout.VStack>

        {imageUrl && (
          <Image
            {...styles.moduleThumbnail}
            alt={imageAlt}
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}
      </Layout.HStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  moduleBox: {
    backgroundColor: 'secondary.50',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'medium',
  },

  moduleBoxCompleted: {
    backgroundColor: 'secondary.200',
    shadow: 'none',
  },

  moduleBoxTop: {
    alignItems: 'flex-end',
    height: 8,
  },

  moduleBoxTopHorizontal: {
    height: 4,
  },

  moduleBoxStack: {
    alignItems: 'center',
    textAlign: 'left',
  },

  moduleBoxStackHorizontal: {
    justifyContent: 'space-between',
    textAlign: 'left',
    paddingBottom: 4,
    paddingX: 4,
  },

  moduleThumbnail: {
    alignSelf: 'center',
    size: '72px',
  },

  skillCompletedText: {
    opacity: 0.9,
  },
});
