import { defaultTo, range } from 'lodash';
import { Heading, Layout, Pressable } from '../../../../../components/core';
import { RichText } from '../../../../../components/prismic';
import { useSkillContext } from '../../../../../contexts';
import { getStylesheet } from '../../../../../styles';
import { RatingSlideSlice } from '../../../SkillTypes';
import { SkillWrapper } from '../../SkillWrapper';

import type { SliceContext } from '../../skillTypes';

type RatingSlideProps = {
  context: SliceContext;
  slice: RatingSlideSlice;
};

export const RatingSlide = ({ context, slice }: RatingSlideProps): JSX.Element => {
  const { triggerNextSlide, ratingsBySlide, ratingsDispatch } = useSkillContext();

  const mainItem = slice.primary;
  const lowEndLabel = defaultTo(mainItem.low_end_label, '');
  const highEndLabel = defaultTo(mainItem.high_end_label, '');

  const currentRating = ratingsBySlide[context.slideIndex]?.rating;

  const onRatingPress = (ratingValue: number) => (): void => {
    if ('id' in mainItem.linked_module && 'id' in mainItem.linked_section) {
      ratingsDispatch({
        slideNumber: context.slideIndex,
        moduleId: mainItem.linked_module.id,
        sectionId: mainItem.linked_section.id,
        rating: ratingValue,
        nudge: 'id' in mainItem.nudge ? mainItem.nudge.id : '',
      });
    }

    triggerNextSlide();
  };

  return (
    <SkillWrapper hideContinueButton>
      <RichText field={mainItem.content} />

      <Layout.VStack space={2}>
        <Layout.Flex {...styles.buttons}>
          {range(1, 6).map(ratingValue => {
            const isActive = currentRating === ratingValue;

            return (
              <Pressable
                {...styles.buttonPressable}
                {...(isActive ? styles.buttonActive : {})}
                key={ratingValue}
                testID={`pressable-skill-rating-slide-${ratingValue}`}
                onPress={onRatingPress(ratingValue)}
              >
                <Heading.h5
                  {...styles.buttonHeading}
                  {...(isActive ? styles.buttonHeadingActive : {})}
                >
                  {ratingValue}
                </Heading.h5>
              </Pressable>
            );
          })}
        </Layout.Flex>

        <Layout.HStack {...styles.labels}>
          <Heading.h6>{lowEndLabel}</Heading.h6>
          <Heading.h6>{highEndLabel}</Heading.h6>
        </Layout.HStack>
      </Layout.VStack>
    </SkillWrapper>
  );
};

export default RatingSlide;

const styles = getStylesheet({
  buttons: {
    flexDirection: 'row',
    gap: 2,
    justifyContent: 'space-between',
  },

  buttonPressable: {
    alignItems: 'center',
    backgroundColor: 'primary.alpha.10:alpha.10',
    borderColor: 'primary.600',
    borderRadius: '8px',
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    height: '48px',
  },

  buttonActive: {
    backgroundColor: 'primary.600',
  },

  buttonHeading: {
    color: 'primary.600',
  },

  buttonHeadingActive: {
    color: 'lightText',
  },

  labels: {
    justifyContent: 'space-between',
  },
});
