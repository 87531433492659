import { kebabCase } from 'lodash';
import React from 'react';
import { Heading, Layout, Text, Pressable } from '../../../components/core';
import { useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type QuickActionProps = {
  icon: JSX.Element;
  title: string;
  caption: string;
  linkTo: string;
};

export const QuickActionCard = ({
  icon,
  title,
  caption,
  linkTo,
}: QuickActionProps): JSX.Element => {
  const navigate = useNavigate();

  const onClickNav = () => (): void => {
    navigate(linkTo);
  };

  const titleAsId = kebabCase(title);

  return (
    <Pressable
      {...styles.quickAction}
      onPress={onClickNav()}
      testID={`pressable-home-page-go-to-${titleAsId}-page`}
      accessibilityLabel={`Navigate to ${title} page`}
    >
      <Layout.VStack {...styles.topStack} space={4}>
        {icon}

        <Layout.VStack alignItems="center" space={2}>
          <Heading.h4 {...styles.text} center>
            {title}
          </Heading.h4>

          <Text.caption center>{caption}</Text.caption>
        </Layout.VStack>
      </Layout.VStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  quickAction: {
    alignItems: 'center',
    backgroundColor: 'secondary.50',
    borderRadius: 4,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '45%',
    shadow: 'large',
    paddingX: 4,
    paddingY: 6,
  },

  topStack: {
    alignItems: 'center',
  },

  text: {
    width: '100%',
    maxWidth: '130px',
  },
});
