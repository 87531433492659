import { Pressable as BasePressable, IPressableProps } from 'native-base';
import { forwardRef } from 'react';

export type PressableProps = IPressableProps & {
  testID: string;
};

export const Pressable = forwardRef(({ children, ...props }: PressableProps, ref): JSX.Element => {
  return (
    <BasePressable {...props} ref={ref}>
      {children}
    </BasePressable>
  );
});
